import * as React from 'react';

/**
 * Error Page
 */
class NotFound extends React.PureComponent {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		return <div> URL Not found </div>;
	}
}

export default NotFound;
